<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Прилади обліку
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <MainModal
            :main="{ component: 'ApplianceByFlat', title: 'Прилади обліку' }"
            :button="{
                    icon: true,
                    color: 'grey lighten-4',
                    buttonIcon: 'mdi-plus',
                    buttonName: '',
                    buttonOutlined: false,
                    buttonIconClass: ''
                  }"
            :item="{flat_id: flat.id}"
        />
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openItemModal"
    >
      <template v-slot:item.icon="{ item }">
        <MainModal
            :main="{ component: 'ApplianceByFlat', title: 'Прилади обліку' }"
            :button="{ iconActivator: true, class: 'mr-0',
                      color: getApplianceType(item.appliance_type, 'color'),
                      buttonIcon: getApplianceType(item.appliance_type, 'icon'),
                      iconSize: 26 }"
            :item="{
                      id: item.id,
                      flat_id: item.flat_id,
                      service_id: item.service_id,
                      appliance_type: item.appliance_type,
                      appliance_model_id: item.appliance_model_id,
                      date_start: item.date_start,
                      date_end: item.date_end,
                      item_count: item.item_count,
                    }"
            :modal="item_dialog_id === item.id"
            @updateItemModal="updateItemModal"
        />
      </template>
      <template v-slot:item.row_checkbox="{ item }">
        <v-checkbox
            class="select-checkbox mt-0"
            color="success"
            hide-details :ripple="false"
            @click.stop
            :value="selected_row === item.id"
            @change="changeSelect(item.id)"
        />
      </template>

      <template v-slot:item.appliance_type="{ item }">
        <span>
          {{ getApplianceType(item.appliance_type) }}
        </span>
      </template>

      <template v-slot:item.service_icon="{ item }">
        <span>
          <v-icon
              :color="getServiceIcon(item.service_icon, 'color')"
              size="26"
              class="mr-1"
          >
            {{ getServiceIcon(item.service_icon, 'icon') }}
          </v-icon>
        </span>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.date_start | formatDate }}
        </span>
      </template>

      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.date_end | formatDate }}
        </span>
      </template>

      <template v-slot:item.item_count="{ item }">
        <span>
          {{ item.item_count | formatNumber }}
        </span>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {getApplianceType, getServiceIcon} from "@/utils/icons"
import {
  GET_ALL_APPLIANCE_BY_FLAT,
  APPLIANCE_BY_FLAT_CURRENT_SELECT,
  GET_ALL_APPLIANCE_OPERATION_JOURNAL
} from "@/store/actions/appliance";

export default {
  name: "HWP_ApplianceByFlat",
  components: { MainModal },
  data() {
    return {
      item_dialog_id: 0,
      selected_row: 0,
      show_actual: true,
      houseParameterHeaders: [
        { text: '', value: 'row_checkbox', width: 26 },
        { text: '', value: 'icon', width: 26 },
        { text: '', value: 'service_icon', width: 26  },
        { text: 'Тип приладу', value: 'appliance_type' },
        { text: 'Модель', value: 'appliance_model_name' },
        { text: 'К-сть', value: 'item_count' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'getApplianceByFlat',
      flat: 'getFlat',
      // selectedCounter: 'getSelectedCounter',
    }),
  },
  methods: {
    ...mapActions({
      fetchItems: GET_ALL_APPLIANCE_BY_FLAT,
      fetchJournal: GET_ALL_APPLIANCE_OPERATION_JOURNAL,
    }),
    getServiceIcon,
    getApplianceType,
    changeSelect(row_num) {
      if (this.selected_row === row_num) {
        this.selected_row = 0

        this.$store.dispatch(APPLIANCE_BY_FLAT_CURRENT_SELECT, {})
      } else {
        this.selected_row = row_num
        const row_obj = this.items.find(item => item.id === this.selected_row)
        this.$store.dispatch(APPLIANCE_BY_FLAT_CURRENT_SELECT, row_obj)
      }
    },
    updateItemModal(payload) {
      this.item_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openItemModal(row) {
      this.item_dialog_id = row.id
    }
  },
  created() {
    this.fetchItems(this.flat.id)
        .then(res => {
          if (res) {
            this.fetchJournal(this.flat.id)
                .then(() => {
                  if (this.items.length >= 1) {
                    this.changeSelect(this.items[0].id)
                  }
                })
          }
        })
  },
  beforeDestroy() {
    this.$store.dispatch(APPLIANCE_BY_FLAT_CURRENT_SELECT, {})
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload && this.flat.id) {
          this.fetchItems(this.flat.id)
              .then(res => {
                if (res) {
                  this.fetchJournal(this.flat.id)
                      .then(() => {
                        if (this.items.length >= 1) {
                          this.changeSelect(this.items[0].id)
                        }
                      })
                }
              })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>