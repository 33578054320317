var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Прилади обліку ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'ApplianceByFlat', title: 'Прилади обліку' },"button":{
                  icon: true,
                  color: 'grey lighten-4',
                  buttonIcon: 'mdi-plus',
                  buttonName: '',
                  buttonOutlined: false,
                  buttonIconClass: ''
                },"item":{flat_id: _vm.flat.id}}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.houseParameterHeaders,"items":_vm.items,"hide-default-footer":""},on:{"click:row":_vm.openItemModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'ApplianceByFlat', title: 'Прилади обліку' },"button":{ iconActivator: true, class: 'mr-0',
                    color: _vm.getApplianceType(item.appliance_type, 'color'),
                    buttonIcon: _vm.getApplianceType(item.appliance_type, 'icon'),
                    iconSize: 26 },"item":{
                    id: item.id,
                    flat_id: item.flat_id,
                    service_id: item.service_id,
                    appliance_type: item.appliance_type,
                    appliance_model_id: item.appliance_model_id,
                    date_start: item.date_start,
                    date_end: item.date_end,
                    item_count: item.item_count,
                  },"modal":_vm.item_dialog_id === item.id},on:{"updateItemModal":_vm.updateItemModal}})]}},{key:"item.row_checkbox",fn:function({ item }){return [_c('v-checkbox',{staticClass:"select-checkbox mt-0",attrs:{"color":"success","hide-details":"","ripple":false,"value":_vm.selected_row === item.id},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.changeSelect(item.id)}}})]}},{key:"item.appliance_type",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getApplianceType(item.appliance_type))+" ")])]}},{key:"item.service_icon",fn:function({ item }){return [_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.getServiceIcon(item.service_icon, 'color'),"size":"26"}},[_vm._v(" "+_vm._s(_vm.getServiceIcon(item.service_icon, 'icon'))+" ")])],1)]}},{key:"item.date_start",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}},{key:"item.date_end",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_end))+" ")])]}},{key:"item.item_count",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.item_count))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }